import { Categories, CategoryKey } from "@/types/odience";
import { categoryImages } from "@/utils/constants/odience";
import { OdienceEvent } from "@/utils/hooks/useDirectorAuthentication";
import { generatePath } from "react-router-dom";

export function getUniqueCategories(events: OdienceEvent[]) {
  const uniqueCategoriesSet = new Set<(typeof events)[number]["category"]>();

  for (const event of events) {
    if (Object.prototype.hasOwnProperty.call(event, "category")) {
      uniqueCategoriesSet.add(event.category);
      uniqueCategoriesSet.add("all");
    }
  }

  const sortedCategoriesArray = Array.from(uniqueCategoriesSet).sort((a, b) => {
    if (a === "all") return -1;
    if (b === "all") return 1;
    return a.localeCompare(b);
  });

  return sortedCategoriesArray;
}

export function getCategoryImage(category: CategoryKey | string): string {
  return categoryImages[category];
}

export function availableCategories(
  objEvents: OdienceEvent[],
  categories: Categories
) {
  const uniqueCategories = getUniqueCategories(objEvents);
  const commonCategories: Partial<Categories> = { all: "All Events" };
  for (const category of uniqueCategories) {
    if (categories[category]) {
      commonCategories[category] = categories[category];
    }
  }
  return commonCategories;
}

export const navigateToEventDetails = (
  path: string,
  groupId: string,
  eventId: string
) => {
  const eventDetailsPath = generatePath(path, { groupId, eventId });
  return eventDetailsPath;
};

export const formatDate = (date: number) => {
  const eventDate = new Date(date * 1000);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };

  const parts = new Intl.DateTimeFormat("en", options).formatToParts(eventDate);
  let formattedDay = "";
  let formattedMonth = "";
  let formattedYear = "";

  for (const part of parts) {
    if (part.type === "day") {
      formattedDay = part.value;
    } else if (part.type === "month") {
      formattedMonth = part.value;
    } else if (part.type === "year") {
      formattedYear = part.value;
    }
  }

  return { formattedDay, formattedMonth, formattedYear };
};
